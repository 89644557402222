<template>
  <div
    v-editable="blok"
    class="picture"
    :class="[
      thumbnail?.filename && `picture--with-thumbnail-${truePosition}`,
      !removeBorderRadius && 'picture--with-border-radius',
      !removeBorderRadius &&
        flipradius &&
        'picture--with-border-radius-flipped',
    ]"
    :style="{
      '--parent-width': `${makeImage(blok.image).width}`,
    }"
  >
    <picture
      class="picture__image"
      :class="[
        blok.overlay_alpha &&
          blok.overlay_alpha !== '0' &&
          'picture__image--with-overlay',
      ]"
      :style="{
        '--overlay-alpha':
          blok.overlay_alpha && blok.overlay_alpha !== '0'
            ? `${blok.overlay_alpha}`
            : '0',
      }"
    >
      <template v-if="blok.mobile && blok.mobile.filename">
        <source
          media="(min-width: 768px)"
          type="image/jpeg"
          :alt="makeImage(blok.image).alt"
          :srcset="makeImage(blok.image).src"
          :height="makeImage(blok.image).height"
          :width="makeImage(blok.image).width"
        />
        <img
          :loading="loading"
          :alt="makeImage(blok.mobile, { mobile: true }).alt"
          :src="makeImage(blok.mobile, { mobile: true }).src"
          :height="makeImage(blok.mobile, { mobile: true }).height"
          :width="makeImage(blok.mobile, { mobile: true }).width"
        />
      </template>
      <template v-else>
        <img
          :loading="loading"
          :alt="makeImage(blok.image).alt"
          :src="makeImage(blok.image).src"
          :height="makeImage(blok.image).height"
          :width="makeImage(blok.image).width"
        />
      </template>
    </picture>
    <p v-if="blok.image.title" class="picture__caption">
      {{ blok.image.title }}
    </p>
    <div v-if="thumbnail && thumbnail.filename" class="picture__thumbnail">
      <img
        :loading="loading"
        :alt="makeImage(thumbnail).alt"
        :src="makeImage(thumbnail).src"
        :height="makeImage(thumbnail).height"
        :width="makeImage(thumbnail).width"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ImageBlokProps, PictureBlokProps } from '@/types';

// Props
const props = withDefaults(
  defineProps<{
    blok: PictureBlokProps;
    flipradius?: boolean;
    height?: number;
    loading?: 'lazy' | 'eager';
    position?: 'left' | 'middle' | 'right';
    removeradius?: boolean;
    smallheight?: number;
    smallwidth?: number;
    thumbnail?: ImageBlokProps;
    thumbnailposition?: 'left' | 'middle' | 'right';
    width?: number;
  }>(),
  {
    flipradius: false,
    height: 0,
    loading: 'lazy',
    position: 'right',
    removeradius: false,
    smallheight: 0,
    smallwidth: 0,
    thumbnail: undefined,
    thumbnailposition: 'left',
    width: 0,
  }
);

const getDimensions = (
  image: ImageBlokProps,
  dimensions?: { height?: number; width?: number }
) => ({
  height:
    dimensions?.height && dimensions.height !== 0
      ? dimensions.height
      : image.height || Number(image.filename.split('/')[5].split('x')[1]),
  width:
    dimensions?.width && dimensions.width !== 0
      ? dimensions.width
      : image.width || Number(image.filename.split('/')[5].split('x')[0]),
});

const makeImage = (image: ImageBlokProps, options?: { mobile?: boolean }) => {
  const alt = image.alt;
  const height =
    options?.mobile && props.smallheight
      ? Number(props.smallheight)
      : Number(props.height);
  const width =
    options?.mobile && props.smallwidth
      ? Number(props.smallwidth)
      : Number(props.width);

  const dimensions = getDimensions(
    image,
    options
      ? {
          height,
          width,
        }
      : {}
  );

  const src = imageService(image.filename, {
    focus: image.focus,
    size: dimensions.width + 'x' + dimensions.height,
  });

  return {
    ...dimensions,
    alt,
    src,
  };
};

const removeBorderRadius = computed(() => Boolean(props.removeradius));

const truePosition = computed(() =>
  props.thumbnailposition ? props.thumbnailposition : props.position
);
</script>

<style lang="scss">
.picture {
  display: inline-block;
  position: relative;
  vertical-align: top;

  .picture__image {
    display: block;

    &--with-overlay {
      position: relative;

      &::before {
        background-color: rgb(0 0 0 / var(--overlay-alpha));
        content: '';
        inset: 0;
        position: absolute;
      }
    }
  }

  &--with-border-radius {
    &,
    .picture__image {
      border-start-end-radius: 24px;
      overflow: hidden;
    }

    @media all and #{$mq-m-min} {
      &-flipped {
        &,
        .picture__image {
          border-start-end-radius: 0;
          border-start-start-radius: 24px;
        }
      }
    }
  }

  &--with-thumbnail {
    &-middle {
      display: grid;
      width: fit-content;

      > * {
        grid-area: 1 / 1;
      }

      > picture {
        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .picture__thumbnail {
        padding-block: 20%;
        padding-inline: 20%;
        place-self: center;
        text-align: center;

        img {
          min-width: 160px;
          width: 100%;
        }
      }
    }

    &-left {
      padding-inline-start: columns(1);

      .picture__thumbnail {
        inset-inline-start: 0;
        text-align: start;
      }

      @media all and #{$mq-s-max} {
        padding-inline-start: columns(3.5);
      }
    }

    &-right {
      padding-inline-end: columns(1);

      .picture__thumbnail {
        inset-inline-end: 0;
        text-align: end;
      }

      @media all and #{$mq-s-max} {
        padding-inline-end: columns(3.5);
      }
    }

    &-left,
    &-right {
      .picture__thumbnail {
        inset-block-end: 20%;
        max-width: 260px;
        min-width: 100px;
        position: absolute;
        width: columns(4.5);

        @media all and #{$mq-m-min} {
          width: 45%;
        }

        .card__media & {
          max-width: 210px;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  &__caption {
    color: var(--gray);
    display: none;
    font-size: 0.875rem;
    margin-block-start: 16px;

    @media all and #{$mq-m-min} {
      font-size: 1.125rem;
    }
  }
}
</style>
